import styles from './style.module.css'
import FlatButton from '@components/FlatButton'

function FAB() {
  return (
    <FlatButton className={styles.FABContainer} target="_blank" link="https://wa.me/+6281808110888" />
  )
}

export default FAB
